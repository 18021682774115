import Vue from "vue";
import Router from "vue-router";
import HomePage from "@/pages/HomePage";
import LoginPage from "@/pages/LoginPage";
import RegisterPage from "@/pages/RegisterPage";
import ResetPasswordPage from "@/pages/ResetPasswordPage";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    { name: "Home", path: "/", component: HomePage },
    { name: "Login", path: "/login", component: LoginPage },
    { name: "Register", path: "/register", component: RegisterPage },
    {
      name: "Reset Password",
      path: "/reset-password",
      component: ResetPasswordPage,
    },

    // otherwise redirect to home
    { path: "*", redirect: "/" },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login", "/register", "/reset-password"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  next();
});
