<template>
  <div class="login-page">
    <div fluid class="container-fluid main">
      <Header />
      <div class="content" :class="{ scrolled: scrolled }" @scroll="handleScroll">
        <div class="container" v-if="!isIE">
          <div class="row">
            <div class="col-md-12 col-lg-4 primary">
              <img src="~@/assets/images/small-logo.png" width="30" height="30" class="logo" alt="Netstar" />
              <div class="heading"><strong>WELCOME</strong><br />BACK!</div>
              <div class="spacer"></div>
              <div class="form-container">
                <Alert />
                <form @submit.prevent="handleSubmit">
                  <div class="form-group">
                    <input
                      type="text"
                      v-model="username"
                      id="username"
                      name="username"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && !username }"
                    />
                    <label for="username">Username</label>
                    <div v-show="submitted && !username" class="invalid-feedback">Username is required</div>
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      autocomplete="on"
                      v-model="password"
                      id="password"
                      name="password"
                      class="form-control"
                      :class="{ 'is-invalid': submitted && !password }"
                    />
                    <label for="password">Password</label>
                    <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
                  </div>
                  <div class="form-group buttons">
                    <button class="btn btn-submit" :disabled="status.loggingIn">
                      <span v-show="!status.loggingIn">LOG IN</span>
                      <div class="d-flex justify-content-center" v-if="status.loggingIn">
                        <Spinner />
                        <span class="ml-2" v-if="isSlowToLogin">Still busy...</span>
                      </div>
                    </button>
                    <!-- <router-link to="reset-password" class="text-link text-right">Forgot Username / Password?</router-link> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <IncompatibleBrowser v-else />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";

$horizontal-padding: 16px;

.login-page {
  .main {
    display: flex;
    background: #fff url("~@/assets/images/background.jpg") no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    padding: 0;
  }

  .content {
    flex-grow: 1;
    padding: 0 20px;

    > .container {
      display: flex;
      flex-direction: column;
      height: 100%;

      .row {
        flex: 1;
        align-items: center;
      }

      .primary {
        display: flex;
        flex-direction: column;
        padding-top: 150px;

        > .logo {
          display: none;
        }

        .heading {
          font-size: 2.5rem;
          line-height: 1;
          color: $brand-color-1;

          > div {
            line-height: 42px;

            &.last {
              color: $brand-color-2;

              span {
                font-family: $font-mont-book;
                font-style: italic;
              }
            }
          }
        }

        .spacer {
          height: 50px;
          margin: 15px 0;
          border-left: 1px solid $brand-color-2;
        }

        .form-container {
          width: 280px;

          form {
            width: 100%;

            .form-control {
              background: rgba(0, 0, 0, 0.05);
              height: auto;
              line-height: 1;
              color: $brand-color-1;
              padding: 5px $horizontal-padding;
              border-radius: 0;
              border: none;
              box-shadow: none;

              &:disabled {
                opacity: 0.5;
              }
            }

            label {
              display: block;
              background: $brand-color-2;
              font-weight: bold;
              font-size: 0.75rem;
              line-height: 1;
              color: #fff;
              padding: 4px $horizontal-padding 3px $horizontal-padding;
              margin-bottom: 0;
            }

            .buttons {
              display: flex;
              flex-direction: column;
              align-items: flex-end;

              .btn-submit {
                background: $brand-color-2;
                font-weight: bold;
                min-width: 110px;
                font-size: 0.75rem;
                font-weight: 600;
                color: #fff;
                padding: 10px 30px;
                margin: 10px 0 20px 0;
                border-radius: 20px;
                border: none;

                &:hover {
                  background: $brand-color-2;
                  border-color: $brand-color-2;
                }

                &:disabled {
                  opacity: 1;
                }
              }

              .text-link {
                display: inline-block;
                font-style: italic;
                font-weight: 600;
                color: $brand-color-2;
              }
            }

            .invalid-feedback {
              background: $brand-color-2;
              font-size: 0.75rem;
              font-weight: 600;
              padding: 0 $horizontal-padding 3px $horizontal-padding;
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .login-page {
    .main {
      // background-image: url("~@/assets/images/background-mobile.jpg");
      padding: 20px;
    }

    header {
      display: none;
    }

    .content {
      background: #fff;
      height: fit-content;
      max-width: 340px;
      padding: 0 40px;
      margin: auto;

      > .container {
        .primary {
          flex: 0 0 100%;
          max-width: 100%;
          position: relative;
          padding: 50px 0 0 0;

          > .logo {
            display: block;
            margin: -20px 0 20px 0;
          }

          .heading {
            font-size: 1.875rem;

            > div {
              line-height: 32px;
            }
          }

          .form-container {
            width: 100%;
          }
        }

        .column-spacer {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 480px) and (orientation: portrait) {
  .login-page {
    .content {
      position: relative;
      max-height: calc(100vh - 40px);
      overflow-y: scroll;

      &:before {
        content: "";
        background: none;
        display: block;
        position: sticky;
        left: 0;
        top: 0;
        width: 100%;
        height: 50px;
        z-index: 1;
      }

      &.scrolled:before {
        background: linear-gradient(white 0%, rgba(255, 255, 255, 0) 100%);
      }

      &:after {
        content: "";
        background: linear-gradient(rgba(255, 255, 255, 0) 0%, white 100%);
        display: block;
        position: sticky;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50px;
        z-index: 1;
      }

      > .container {
        .primary {
          padding-top: 0;

          .spacer {
            height: 0 !important;
          }
        }
      }
    }
  }
}
</style>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Header from "@/pages/components/Header";
import IncompatibleBrowser from "@/components/IncompatibleBrowser";

export default {
  components: {
    Header,
    IncompatibleBrowser,
  },
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
      scrolled: false,
      isSlowToLogin: false,
    };
  },
  computed: {
    ...mapState("account", ["status"]),
    ...mapState(["alert"]),
    ...mapState("pwa", ["deferredPromptForInstall", "canInstallApp"]),
    isIE() {
      return /*@cc_on!@*/ false || !!document.documentMode;
    },
  },
  created() {
    this.logout();
  },
  methods: {
    ...mapActions("account", ["login", "logout"]),
    ...mapMutations("pwa", ["updateDeferredPromptForInstall"]),
    promptInstall() {
      // if (this.deferredPromptForInstall) {
      //   this.deferredPromptForInstall.prompt();
      //   this.deferredPromptForInstall.userChoice.then((choiceResult) => {
      //     // if (choiceResult.outcome === "accepted") {
      //     //   console.log("User accepted the install prompt");
      //     // } else {
      //     //   console.log("User dismissed the install prompt");
      //     // }
      //     this.updateDeferredPromptForInstall(null);
      //   });
      // } else if (this.$isIOS) {
      //   tooltipService.showTooltip("install-app");
      // }
    },
    handleScroll(event) {
      this.scrolled = event.target.scrollTop > 5;
    },
    handleSubmit(e) {
      this.submitted = true;
      this.isSlowToLogin = false;
      const { username, password } = this;
      if (username && password) {
        setTimeout(() => {
          this.isSlowToLogin = true;
        }, 4000);
        this.login({ username, password });
      }
    },
  },
};
</script>
