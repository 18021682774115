<template>
  <div class="toggle-component" @click="handleClick">
    <img src="~@/assets/images/sidebar/chevron-up.svg" width="20" height="20" alt="Collapse" v-if="isActive" />
    <img src="~@/assets/images/sidebar/chevron-down.svg" width="20" height="20" alt="Expand" v-else />
  </div>
</template>

<style lang="scss" scoped>
.toggle-component {
  &:hover {
    cursor: pointer;
  }
}
</style>

<script>
export default {
  name: "Toggle",
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isActive: this.active,
    };
  },
  methods: {
    handleClick() {
      this.isActive = !this.isActive;
      this.$emit("update:active", this.isActive);
    },
  },
  watch: {
    active(value) {
      this.isActive = value;
    },
  },
};
</script>