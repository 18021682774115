import Users from "@/components/user-management/Users";

export const componentService = {
  getComponent,
};

function getComponent(componentName) {
  let component = null;

  switch (componentName) {
    case "userManagement.users":
      component = Users;
      break;
  }

  return component;
}
