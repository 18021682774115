<template>
  <div class="home-page">
    <header>
      <div :class="{ shadowWrap: !show && !$isMobile }">
        <div class="profile" :class="{ active: show }">
          <div class="welcome">
            <div>Welcome</div>
            <div class="name">{{ getDisplayName }}</div>
          </div>
          <img :src="getProfileImageUrl" class="inside-menu" width="32" height="32" alt="Avatar" v-show="!$isMobile || ($isMobile && show)" />
          <button class="menu-button" key="on" @click="updateShow(false)" v-if="show">
            <svg viewBox="0 0 24 24" class="menu-icon">
              <title>Close</title>
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
              />
            </svg>
          </button>
          <button class="menu-button" key="off" @click="updateShow(true)" v-else>
            <img :src="getProfileImageUrl" width="42" height="42" alt="Avatar" v-if="$isMobile && getProfileImageUrl" />
            <svg viewBox="0 0 100 80" width="20" height="20" v-else>
              <rect width="100" height="10" />
              <rect y="30" width="100" height="10" />
              <rect y="60" width="100" height="10" />
            </svg>
          </button>
        </div>
      </div>
    </header>
    <div class="main">
      <div class="sidebar">
        <div class="top">
          <div class="logo">
            <img src="~@/assets/images/small-logo.png" width="32" height="32" alt="Netstar" />
          </div>
          <div class="title">UNIT MANAGEMENT</div>
        </div>
        <div class="sidebar-menu">
          <div class="menu-item">
            <div class="parent">
              <div class="category" @click="loadComponent('sims')">
                <div class="icon">
                  <img src="~@/assets/images/sidebar/sims.svg" width="28" height="28" alt="SIMs" />
                </div>
                <div class="title">SIMs</div>
              </div>
            </div>
          </div>
          <div class="menu-item">
            <div class="parent">
              <div class="category" @click="loadComponent('units')">
                <div class="icon">
                  <img src="~@/assets/images/sidebar/units.svg" width="28" height="28" alt="Units" />
                </div>
                <div class="title">Units</div>
              </div>
            </div>
          </div>
          <div class="menu-item">
            <div class="parent">
              <div class="category" @click="loadComponent('worklists')">
                <div class="icon">
                  <img src="~@/assets/images/sidebar/worklists.svg" width="28" height="28" alt="Worklists" />
                </div>
                <div class="title">Worklists</div>
              </div>
            </div>
          </div>
          <div class="menu-item">
            <div class="parent">
              <div class="category" @click="loadComponent('msisdn')">
                <div class="icon">
                  <img src="~@/assets/images/sidebar/msisdn.svg" width="28" height="28" alt="MSISDN" />
                </div>
                <div class="title">MSISDN</div>
              </div>
            </div>
          </div>
          <div class="menu-item">
            <div class="parent">
              <div class="category" @click="loadComponent('auditTrail')">
                <div class="icon">
                  <img src="~@/assets/images/sidebar/audit-trail.svg" width="28" height="28" alt="Audit Trail" />
                </div>
                <div class="title">Audit Trail</div>
              </div>
              <!-- <Toggle v-bind:active.sync="sidebarMenu.auditTrail.submenuOpen" /> -->
            </div>
            <!-- <SmoothReflow>
              <div class="submenu" v-if="sidebarMenu.auditTrail.submenuOpen">
                <div class="submenu-item" :class="{ current: isActive('auditTrail.users') }" @click="loadComponent('auditTrail.users')">Users</div>
                <div class="submenu-item" :class="{ current: isActive('auditTrail.roles') }" @click="loadComponent('auditTrail.roles')">
                  Roles & Permissions
                </div>
              </div>
            </SmoothReflow> -->
          </div>
          <div class="menu-item">
            <div class="parent">
              <div class="category" @click="loadComponent('userManagement.users')">
                <div class="icon">
                  <img src="~@/assets/images/sidebar/user-management.svg" width="28" height="28" alt="User Management" />
                </div>
                <div class="title">User Management</div>
              </div>
              <Toggle v-bind:active.sync="sidebarMenu.userManagement.submenuOpen" />
            </div>
            <SmoothReflow>
              <div class="submenu" v-if="sidebarMenu.userManagement.submenuOpen">
                <div class="submenu-item" :class="{ current: isActive('userManagement.users') }" @click="loadComponent('userManagement.users')">Users</div>
                <div class="submenu-item" :class="{ current: isActive('userManagement.roles') }" @click="loadComponent('userManagement.roles')">
                  Roles & Permissions
                </div>
              </div>
            </SmoothReflow>
          </div>
          <!-- <button @click="loadComponent('userManagement.roles')">LOAD</button> -->
        </div>
      </div>
      <div class="content">
        <component class="component" :is="component" v-bind="componentProps" />
      </div>
    </div>
    <Menu />
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
@import "~@/assets/scss/mixins.scss";

@include scrollbar;

// main {
//   position: fixed;
//   top: 0;
//   left: 0;
//   height: 100%;
//   width: 100%;
// }

.home-page {
  header {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 52px;
    z-index: 1;

    .mobile & {
      z-index: 3;
    }

    .shadowWrap {
      position: relative;
      box-shadow: $drop-shadow;
      transition: filter 2s;
    }

    // .logo {
    //   display: flex;
    //   align-items: center;
    //   background: #fff;
    //   width: 75px;
    //   height: 100%;
    //   padding: 0 0 0 25px;

    //   &:after {
    //     content: " ";
    //     background: #fff;
    //     position: absolute;
    //     width: 20px;
    //     height: 100%;
    //     right: -10px;
    //     top: 0;
    //     z-index: 0;
    //     transform: skew(-20deg);
    //     box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);
    //   }
    // }

    .profile {
      display: flex;
      align-items: center;
      background: #fff;
      width: 250px;
      height: 100%;
      line-height: 1;
      font-weight: bold;
      font-size: 0.8125rem;
      font-style: italic;
      color: $brand-color-1;
      text-align: right;
      text-transform: uppercase;
      padding-right: 10px;

      &:before {
        content: " ";
        background: #fff;
        position: absolute;
        width: 20px;
        height: 100%;
        left: -10px;
        top: 0;
        z-index: 1;
        transform: skew(20deg);
        box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
      }

      &.active {
        box-shadow: $drop-shadow;
      }

      .menu-button {
        svg {
          fill: $brand-color-1;
        }
      }

      .mobile & {
        background: transparent;
        justify-content: flex-end;
        transition: background 1s;

        &:before {
          display: none;
        }

        .menu-button {
          padding: 10px;
        }

        img {
          margin-right: 0;
          box-shadow: $drop-shadow;

          &.inside-menu {
            box-shadow: none;
          }
        }

        .welcome,
        img.inside-menu {
          opacity: 0;
          transform: translateX(-10px);
          transition: all 1s;
        }

        &.active {
          background: #fff;
          box-shadow: none;

          .welcome,
          img.inside-menu {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }

      .welcome {
        flex: 1;
        width: 150px;
        padding: 0 10px 0 15px;

        .name {
          color: $brand-color-2;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      img {
        margin-right: 5px;
        border-radius: 50%;
      }
    }

    .menu-button {
      background-color: transparent;
      color: gray;
      cursor: pointer;
      margin: 0;
      border: 1px solid transparent;

      &:focus {
        outline: 0;
      }

      .menu-icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .main {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    min-height: 100vh;

    > .sidebar {
      flex-basis: 300px;
      flex-grow: 1;
      box-shadow: $drop-shadow;

      .top {
        background: $brand-color-2;
        display: flex;
        align-items: center;
        box-shadow: $drop-shadow;

        .logo {
          background: #fff;
          width: 75px;
          height: 100%;
          padding: 10px 20px;
          clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
        }

        .title {
          flex: 1;
          font-weight: bold;
          text-align: center;
          color: #fff;
        }
      }

      .sidebar-menu {
        padding: 30px 20px;

        .menu-item {
          font-size: 0.75rem;
          padding: 10px 0;
          border-bottom: 1px solid $brand-color-2;

          &:hover {
            cursor: pointer;
          }

          .parent {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .category {
              display: flex;
              align-items: center;
              width: 100%;

              .icon {
                width: 45px;
              }

              .title {
                // color: $brand-color-1;
                font-weight: bold;
                text-transform: uppercase;
              }
            }
          }

          .submenu {
            margin: 2px 0 0 45px;

            .submenu-item {
              text-transform: uppercase;
              padding: 2px 0;

              &.current {
                font-weight: bold;
                color: $brand-color-2;
              }

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    > .content {
      flex-basis: 0;
      flex-grow: 999;
      min-inline-size: 50%;
      padding: 8% 4%;

      .component {
        max-width: 960px;
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { componentService } from "@/services";
import Menu from "@/components/Menu";
import Toggle from "@/components/Toggle";

export default {
  name: "HomePage",
  components: {
    Menu,
    Toggle,
  },
  data() {
    return {
      loading: true,
      sidebarMenu: {
        auditTrail: {
          submenuOpen: false,
        },
        userManagement: {
          submenuOpen: true,
        },
      },
      activeComponentName: "userManagement.users",
      component: null,
      componentProps: null,
    };
  },
  computed: {
    ...mapState("navigation", ["show"]),
    ...mapGetters("account", ["getDisplayName", "getProfileImageUrl"]),
  },
  created() {},
  mounted() {
    this.component = componentService.getComponent(this.activeComponentName);
  },
  beforeDestroy() {},
  methods: {
    ...mapMutations("navigation", ["updateShow"]),
    isActive(componentName) {
      return this.activeComponentName === componentName;
    },
    loadComponent(componentName) {
      this.activeComponentName = componentName;
    },
  },
  watch: {
    activeComponentName(value) {
      for (const key in this.sidebarMenu) {
        this.sidebarMenu[key].submenuOpen = false;
      }
      const featureName = value.split(".")[0];
      if (featureName in this.sidebarMenu) {
        this.sidebarMenu[featureName].submenuOpen = true;
      }
    },
  },
};
</script>