import { api } from "@/services/api.service";

export const userService = {
  login,
  logout,
  register,
  resetPassword,
};

function login(username, password) {
  return new Promise((resolve, reject) => {
    const user = {
      firstName: "Joe",
      surname: "Soap",
      token: "12345",
    };
    localStorage.setItem("user", JSON.stringify(user));
    resolve(user);
  });
  // return api
  //   .post("/token", { username: username, password: password })
  //   .then((request) => {
  //     const user = request.data;
  //     if (user.token) {
  //       localStorage.setItem("user", JSON.stringify(user));
  //     }

  //     return user;
  //   });
}

function logout() {
  localStorage.removeItem("user");
}

function register(params) {
  return api.post("/account/register", params).then((request) => {
    return request.data;
  });
}

function resetPassword(params) {
  return api.post("/account/reset-password", params).then((request) => {
    return request.data;
  });
}
