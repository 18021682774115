<template>
  <div class="users-component screen">
    <div class="header">
      <div class="title">
        <div class="category">USER MANAGEMENT</div>
        <img src="~@/assets/images/component/chevron-right.svg" width="18" height="18" alt="" />
        <div class="feature">USERS</div>
      </div>
      <div class="d-flex align-items-center">
        <div class="search">
          <input type="text" placeholder="SEARCH" v-model="search" />
          <img src="~@/assets/images/component/magnify.svg" width="22" height="22" alt="" />
        </div>
        <button type="button" class="btn btn-outline inverted">ADD USER</button>
      </div>
    </div>
    <div class="panel">
      <table>
        <thead>
          <th>USERNAME</th>
          <th>ROLE</th>
          <th></th>
        </thead>
        <tbody v-for="user in filteredUsers" :key="user.id">
          <tr>
            <td>{{ user.username }}</td>
            <td>{{ user.role }}</td>
            <td>
              <div class="controls">
                <div class="custom-control custom-switch mr-4">
                  <input type="checkbox" class="custom-control-input" :id="'userActive' + user.id" v-model="user.active" />
                  <label class="custom-control-label" :for="'userActive' + user.id" />
                </div>
                <img src="~@/assets/images/component/edit.svg" width="22" height="22" alt="" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.users-component {
  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.25rem;
    margin-bottom: 30px;

    .title {
      display: flex;
      align-items: center;

      .category {
        font-weight: bold;
      }

      img {
        margin: 0 8px;
      }

      .feature {
        font-weight: 300;
        color: $brand-color-2;
      }
    }

    .search {
      display: flex;
      align-items: center;
      padding: 4px 10px;
      margin-right: 25px;
      border-radius: 20px;
      border: 1px solid $brand-color-2;

      input {
        font-size: 0.875rem;
        color: $brand-color-1;
        border: none;

        &:focus {
          outline: none;
        }

        &::placeholder {
          font-size: 0.75rem;
          font-weight: bold;
          color: #e3e5e7;
        }
      }
    }
  }

  .panel {
    table {
      width: 100%;

      thead {
        th {
          padding: 15px;

          &:first-of-type {
            padding-left: 0;
          }
        }
      }

      tbody {
        &:after {
          content: "";
          display: block;
          height: 25px;
        }

        tr {
          background: #fff;
          box-shadow: $drop-shadow;

          td {
            padding: 15px;

            .controls {
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: "Users",
  data() {
    return {
      search: "",
      users: [
        {
          id: 1,
          username: "Netstar\\Nicole",
          role: "Admin",
          active: true,
        },
        {
          id: 2,
          username: "Netstar\\Phumla",
          role: "Admin",
          active: true,
        },
        {
          id: 3,
          username: "Netstar\\Lihle",
          role: "Admin",
          active: true,
        },
        {
          id: 4,
          username: "Netstar\\Brandon",
          role: "Admin",
          active: true,
        },
      ],
    };
  },
  computed: {
    filteredUsers() {
      return this.users.filter(
        (user) => user.username.toLowerCase().includes(this.search.toLowerCase()) || user.role.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
};
</script>