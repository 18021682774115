import Vue from "vue";
import Vuex from "vuex";

import { account } from "./account.module";
import { alert } from "./alert.module";
import { navigation } from "./navigation.module";
import { pwa } from "./pwa.module";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    account,
    alert,
    navigation,
    pwa,
  },
});
